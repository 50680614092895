
import { defineComponent, PropType } from 'vue'
import { ICourseItem } from '@/service/course/types'
import TeacherList from '../../partners/cpns/teacher-list.vue'
import InstitutionsCard from '@/views/cpns/institutions-card.vue'
import VueMarkdownEditor, { xss } from '@kangc/v-md-editor'

export default defineComponent({
  components: {
    TeacherList,
    InstitutionsCard
  },
  emits: ['joinAction', 'tabClickAction'],
  props: {
    course: {
      type: Object as PropType<ICourseItem>,
      required: true
    },
    total: {
      type: Number,
      default: 0
    },
    joined: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const join = () => {
      emit('joinAction')
    }
    const tabClick = (e: any) => {
      emit('tabClickAction', e)
    }
    return {
      join,
      tabClick,
      xss,
      VueMarkdownEditor
    }
  }
})
