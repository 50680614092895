
import { defineComponent, PropType, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb'
import { ICourseItem } from '@/service/course/types'

export default defineComponent({
  components: {
    Breadcrumb
  },
  props: {
    course: {
      type: Object as PropType<ICourseItem>,
      required: true
    },
    total: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const isES = ref(false)
    isES.value = window.location.host === 'es.iioe.org' ? true : false

    return {
      isES
    }
  }
})
