import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_course_detail_banner = _resolveComponent("course-detail-banner")!
  const _component_course_detail_content = _resolveComponent("course-detail-content")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_course_detail_banner, {
      course: _ctx.course,
      total: _ctx.total
    }, null, 8, ["course", "total"]),
    _createVNode(_component_course_detail_content, {
      course: _ctx.course,
      total: _ctx.total,
      joined: _ctx.joined,
      onJoinAction: _ctx.join,
      onTabClickAction: _ctx.tabClick
    }, null, 8, ["course", "total", "joined", "onJoinAction", "onTabClickAction"])
  ]))
}