
import { defineComponent, computed, ref, unref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { ICourseItem } from '@/service/course/types'
import hhRequest from '@/service'
import CourseDetailBanner from './cpns/course-detail-banner.vue'
import CourseDetailContent from './cpns/course-detail-content.vue'
import VueScrollTo from 'vue-scrollto'
import { ElMessageBox } from 'element-plus'

export default defineComponent({
  components: {
    CourseDetailBanner,
    CourseDetailContent
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    // 网络请求
    store.dispatch('course/getCourseDetailAction', {
      url: `/course/${route.params.urlKey}`
    })

    // 从vuex中获取数据
    const course = computed<ICourseItem>(
      () => store.getters['course/courseDetailData']
    )
    // 获取课时数
    // unref()：是 val = isRef(val) ? val.value : val 的语法糖
    const total = computed(() => {
      return unref(course).chapters.reduce((total, chapter) => {
        return (
          total +
          chapter.lessons.reduce((total, lesson) => {
            return total + lesson.duration
          }, 0)
        )
      }, 0)
    })

    const tabClick = (e: any) => {
      let el
      switch (e.index) {
        case '0':
          el = '.introduction'
          break
        case '1':
          el = '.category'
          break
        case '2':
          el = '.teacher-container'
          break

        default:
          return
      }
      VueScrollTo.scrollTo(el, {
        offset: -75
      })
    }

    const joined = ref(unref(course).joined)
    const join = async () => {
      if (joined.value) {
        try {
          await ElMessageBox.confirm(
            window.location.host === 'es.iioe.org'
              ? '¿Quiere cerrar la sesión de aprendizaje?'
              : '',
            {
              confirmButtonText: 'cerrar',
              cancelButtonText: 'cancelar'
            }
          )
          await hhRequest.delete({ url: `/course/${unref(course).id}/join` })
          joined.value = false
        } catch (err: any) {
          return err
        }
      } else {
        await hhRequest.post({
          url: `/course/${unref(course).id}/join`,
          data: {}
        })
        joined.value = true
      }
    }

    return {
      course,
      total,
      joined,
      join,
      tabClick
    }
  }
})
