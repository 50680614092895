
import { defineComponent, PropType } from 'vue'
import { IPartnersItem } from '@/store/partners/types'

export default defineComponent({
  name: 'NetworkCard',
  props: {
    network: {
      type: Object as PropType<IPartnersItem>,
      required: true
    }
  }
})
